import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Link, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: "column",
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
    },
    header: {
        textAlign: "center",
    },
    link: {
        textDecoration: 'underline',
    },
    regionHeader: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    shopName: {
        fontSize: 16,
    }
}));

export default function SupportedSites(props) {
    let classes = useStyles();

    const renderData = () => {
        let currentRegion = "";
        return props.sites.map((site) => {
          let result = [];
          if (currentRegion != site.region) {
            currentRegion = site.region;
            result.push(
              <div key={'site_'+site.region} className={classes.regionHeader}><br/><strong>{site.region}</strong><br/><br/></div>
            )
          }
  
          result.push(
            <div key={site.label} className={classes.shopName}><a href={site.url}>{site.label}</a></div>
          )
          return result;
        })
      }
  
    return (
        <Grid container className={classes.root} alignItems="center">
            <Typography variant="h5" className={classes.header}>
                The following {props.sites.length} online stores are currently supported.<br />
                If you would like a site added, <a href={props.email} target="_blank">contact us</a>
            </Typography>
            {renderData()}
        </Grid>
    )
}