import React from "react";
import ReactMarkdown from 'react-markdown';
import { makeStyles } from "@material-ui/core";

const DEFAULT_BKG_COLOUR = "#aa6a00";
const DEFAULT_TEXT_COLOUR = "white";

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 10,
        marginTop: theme.spacing(1),
        marginBottom: 0,
    },
    notice: {
        padding: "5px 0px 5px 10px",
        fontSize: 14,
        textDecoration: 'none',
        textAlign: 'center',
        "& p": {
            margin: 0,
            color: 'inherit',
            "& a": {
                color: 'inherit',
            }
        }
    }
}));

function LinkRenderer(props) {
    return <a href={props.href} target="_blank">{props.children}</a>
}

export default function NoticeArea(props) {
    let classes = useStyles();

    const renderNotices = () => {
        let notices = props.notices.slice();
        let notice_array = notices.map(notice => {
            let colourOverride = {
                backgroundColor: notice.background_colour ? notice.background_colour : DEFAULT_BKG_COLOUR,
                color: notice.text_colour ? notice.text_colour : DEFAULT_TEXT_COLOUR,
            };
            return (
                <div key={`notice_${notice.id}`} className={classes.root} style={colourOverride}>
                    <ReactMarkdown
                        children={notice.message}
                        className={classes.notice}
                        renderers={{ link: LinkRenderer }}
                    />
                </div>
            )
        });
        return (
            <div>{notice_array}</div>
        )
    }

    if (props.notices && props.notices.length) {
        return (
            <div>
                {renderNotices()}
            </div>
        )
    
    } else {
        return null;
    }
}