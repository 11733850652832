import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, InputLabel, Input, Select } from '@material-ui/core';
import { currencyCodeToSymbol } from '../utils';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    input: {
        // display: 'block',
        marginLeft: 10,
        fontSize: 14,
        fontWeight: 'bold',
        width: 100,
    },
    selectIcon: {
        fontSize: 22,
    }
}));

export default function PriceSelector(props) {
    let classes = useStyles();
    const [valueFrom, setValueFrom] = React.useState("");
    const [valueTo, setValueTo] = React.useState("");

    const onKeyDown = (e) => {
        if (e.keyCode == 13) {
            e.preventDefault();
            e.target.blur();
        }
    }

    const handlePriceFromChange = (e) => {
        let v = e.target.value;
        if (!isNaN(v)) {
            setValueFrom(v);
        }
    }

    const handlePriceToChange = (e) => {
        let v = e.target.value;
        if (!isNaN(v)) {
            setValueTo(v);
        }
    }

    const onBlur = (e) => {
        if (props.onPriceChange) {
            props.onPriceChange(valueFrom, valueTo);
        }
    }

    return (
        <span>
            <Input 
                id="price_from"
                className={classes.input}
                label="Price from"
                placeholder="Price from"
                value={valueFrom}
                onChange={handlePriceFromChange}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                inputProps={{ inputMode: 'decimal' }}
                startAdornment={<InputAdornment position="start">
                    {currencyCodeToSymbol(props.targetCurrency)}
                </InputAdornment>}
            />
            {' - '}
            <Input 
                id="price_from"
                className={classes.input}
                value={valueTo}
                placeholder="Price to"
                onChange={handlePriceToChange}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                inputProps={{ inputMode: 'decimal' }}
                startAdornment={<InputAdornment position="start">
                    {currencyCodeToSymbol(props.targetCurrency)}
                </InputAdornment>}
            />
        </span>
    )
}