import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const postfixes = ["", "k", "M", "B", "T", "q", "Q", "s", "S", "O", "N", 
                   "D", "Ud", "Dd", "Td", "qd", "Qd", "sd", "Sd", "Od", "Nd", 
                   "V", "Uv", "Dv", "Tv", "qv", "Qv", "sv", "Sv", "Ov", "Nv"];

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 350,
        maxWidth: 400,
        textAlign: 'center',
        borderRadius: 15,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        paddingBottom: 5,
        paddingTop: 5,
        fontSize: 15,
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            minWidth: 280,
            fontSize: 10,
            maxWidth: 320,
            marginLeft: -2,
            paddingLeft: 5,
            fontSize: 13,
        },
    },
    label: {
        display: 'block',
        margin: '2px 0px 4px 0px',
        padding: 0,
        color: 'white',
        fontSize: 16,
        fontWeight: 800,
    },
    min_price_text: {
        margin: '2px 10px 2px 10px', 
        display: 'inline-block', 
        color: 'rgb(0,255,0)',
        whiteSpace: 'nowrap',
    },
    avg_price_text: {
        margin: '2px 10px 2px 10px',
        display: 'inline-block',
        color: 'rgb(255,196,0)',
        whiteSpace: 'nowrap',
    },
    max_price_text: {
        margin: '2px 10px 2px 10px',
        display: 'inline-block',
        color: 'rgb(255,64,64)',
        whiteSpace: 'nowrap',
    },
    line: {
        marginBottom: 3,
        height: 5,
        border: 'none',
        minWidth: '10%',
        display: 'inline-block',
        [theme.breakpoints.down('sm')]: {
            minWidth: '8%',
        }
    },
    line_min_to_avg: {
        background: 'linear-gradient(90deg, rgba(0,255,0,1) 0%, rgba(255,196,0,1) 100%)',
    },
    line_avg_to_max: {
        background: 'linear-gradient(90deg, rgba(255,196,0,1) 0%, rgba(255,64,64,1) 100%)',
    }
}));

export default function PriceScale(props) {
    const classes = useStyles();

    return (
        <center>
        <div className={classes.root}>
            <p className={classes.label}>{props.title}</p>
            <p className={classes.min_price_text}>{`${props.currency} ${formatDictionaryNumber(props.min, 2)}`}</p>
            <hr className={clsx(classes.line, classes.line_min_to_avg)} />
            <p className={classes.avg_price_text}>{`${props.currency} ${formatDictionaryNumber(props.avg, 2)}`}</p>
            <hr className={clsx(classes.line, classes.line_avg_to_max)} />
            <p className={classes.max_price_text}>{`${props.currency} ${formatDictionaryNumber(props.max, 2)}`}</p>
        </div>
        </center>
    )
}

function formatDictionaryNumber(val, decimals) {
    if (typeof val == 'string') {
        val = parseFloat(val);
    }
    if (!val) { return val };
    
    const components = val.toExponential(decimals).split("e");
    const roundValTo = (val, roundTo) => { return Math.floor(val/roundTo)*roundTo };
    const getPostFix = (exp) => { return postfixes[Math.floor(exp/3)] };
    let [str, exp] = components;

    exp = parseInt(exp);

    str = (val / Math.pow(10, roundValTo(Math.max(0, exp), 3))).toFixed(decimals) + getPostFix(Math.max(0, exp));

    return str;
}