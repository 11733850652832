import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: 400,
        maxWidth: 400,
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3),
        }
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.dark,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))

export default function ForgotPasswordForm(props) {
    let classes = useStyles();
    const [formStage, setFormStage] = React.useState(1);
    const [email, setEmail] = React.useState("");
    const [password1, setPassword1] = React.useState("");
    const [password2, setPassword2] = React.useState("");
    const [token, setToken] = React.useState("");

    const onInputKeyDown = (e) => {
        if (e.keyCode == 13) {
            e.preventDefault();
            onSubmitClicked(e);
        }
    }

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const onTokenChange = (e) => {
        setToken(e.target.value);
    }

    const onPassword1Change = (e) => {
        setPassword1(e.target.value);
    }

    const onPassword2Change = (e) => {
        setPassword2(e.target.value);
    }

    const onSubmitClicked = (e) => {
        e.preventDefault();
        switch (formStage) {
            case 1:
                props.onSubmitForgotPassword(email);
                setFormStage(2)
                break;

            case 2:
                if (password1 != password2) {
                    alert("Your passwords need to match");
                    break;
                }
                props.onSubmitChangePassword(email, password1, token);
                break;

            default:
                console.error("Unknown form stage: " + formStage);
        }
    }

    const renderForm1 = () => {
        return (
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            onChange={onEmailChange}
                            onKeyDown={onInputKeyDown}
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={onSubmitClicked}
                >
                    Send me the code!
                </Button>
                <Grid container justifyContent="center">
                    <Grid item>
                        <Link href="/login" variant="body2">
                            Go back to login
                        </Link>
                    </Grid>
                </Grid>

            </form>
        )
    }

    const renderForm2 = () => {
        return (
            <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            id="email"
                            name="email"
                            disabled={true}
                            value={email}
                        />
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="password1"
                            label="New Password"
                            type="password"
                            name="password1"
                            onChange={onPassword1Change}
                            onKeyDown={onInputKeyDown}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="password2"
                            label="Confirm Password"
                            type="password"
                            id="password2"
                            onChange={onPassword2Change}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="token"
                            label="Enter the Validation Code from your email"
                            type="text"
                            id="token"
                            onChange={onTokenChange}
                            onKeyDown={(e) => onInputKeyDown(e)}
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={onSubmitClicked}
                >
                    Send me the code!
                </Button>
                <Grid container justifyContent="center">
                    <Grid item>
                        <Link href="/login" variant="body2">
                            Go back to login
                        </Link>
                    </Grid>
                </Grid>

            </form>
        )
    }

    const renderForm = () => {
        switch (formStage) {
            case 1:
                return renderForm1()

            case 2:
                return renderForm2();

            default:
                console.error("Unknown form state: " + formStage);
                break;
        }
    }

    return (
        <div className={classes.root}>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Forgot Password
            </Typography>
            {renderForm()}
        </div>
    )
}