import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import LightModeIcon from '@material-ui/icons/WbSunny';
import DarkModeIcon from '@material-ui/icons/Brightness2';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import TodayIcon from '@material-ui/icons/Today';
import EventIcon from '@material-ui/icons/Event';
import WhatsHotIcon from '@material-ui/icons/Whatshot';
import LogoutIcon from '@material-ui/icons/MeetingRoom';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import {
    useRouteMatch,
} from "react-router-dom";

import { formatNumber } from '../utils';
import SearchBar from './SearchBar';
import AdvertArea from './AdvertArea';
import ResultsArea from "./ResultsArea";
import AlertsArea from './AlertsArea';
import ShopLogoArea from './ShopLogoArea';
import NoticeArea from './NoticeArea';
import StickyFooter from './StickyFooter';
import SignupArea from './SignupArea';
import LoginArea from './LoginArea';
import ForgotPasswordForm from './ForgotPassword';
import PatronsList from './PatronsList';
import SupportedSites from './SupportedSites';
import LandingPage from './LandingPage';
import SupportUs from './SupportUs';

const INFO_EMAIL = process.env.REACT_APP_EMAIL_INFO;
const INFO_SITE_NAME = process.env.REACT_APP_SITE_NAME;
const INFO_SITE_URL = `//${INFO_SITE_NAME}`;
const PATREON_ID = process.env.REACT_APP_PATREON_ID

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    toolbar: {
    },
    toolbarButton: {
        // paddingLeft: 2,
    },
    toolbarIcon: {
        color: 'white',
        fontSize: 20,
    },
    menuIcon: {
        marginRight: 15,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.appBar.backgroundColor,
    },
    searchBar: {
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    titleAndSearch: {
        paddingTop: 10,
        paddingBottom: 10,
        display: "flex",
        flexDirection: "row",
        alignItems: 'center',
        flexGrow: 1,
    },
    appBarSpacer: {
        marginTop: theme.spacing(1),
    },
    divider: {
        margin: theme.spacing(1),
    },
    content: {
        flexGrow: 1,
        overflow: 'auto',
        backgroundColor: theme.palette.content.backgroundColor,
    },
    container: {
        display: "flex",
        flexDirection: "column",
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    backToTopButton: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
        color: 'white'
    },
    menulink: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none',
        color: 'inherit',
        padding: 0,
        margin: 0,
    }
}));

const useTitleStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        alignItems: 'center',
        flexGrow: 1,
        whiteSpace: 'nowrap',
        color: 'white',
        textDecoration: 'none',
    },
    logo: {
        display: 'inline',
        marginRight: theme.spacing(1),
        height: 48,
    },
    title: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
}));

const MENU_OPTIONS = {
    alerts: "alerts",
    login: "login",
    signup: "signup",
    today: "today",
    yesterday: "yesterday",
    popular: "popular",
    mostSearched: "mostsearched",
    // kickstarter: "kickstarter",
    lightdarkmode: "lightdarkmode",
    support: "support",
    logout: "logout",
}

const CURRENCIES = ["USD", "GBP", "EUR", "CAD", "AUD"].sort();

function Title(props) {
    let classes = useTitleStyles();

    return (
        <a className={classes.root} href={INFO_SITE_URL}>
            <img src="/logo.png" className={classes.logo} />
            <Typography component="span" variant="h5" color="inherit" noWrap className={classes.title}>
                {INFO_SITE_NAME}
            </Typography>
        </a>
    )
}

export default function AppFrame(props) {
    const classes = useStyles();
    const [menuAnchorEl, setMenuAnchorElement] = React.useState(null);

    const loadUser = () => {
        let username = props.storage.loadSetting('username');
        let access_token = props.storage.loadSetting('access_token');
        let refresh_token = props.storage.loadSetting('refresh_token');

        if (username && access_token && refresh_token) {
            return { username, access_token, refresh_token }
        }

        return null;
    }

    const logoutUser = () => {
        props.storage.deleteSetting('username');
        props.storage.deleteSetting('access_token');
        props.storage.deleteSetting('refresh_token');
    }

    const updateUserCredentials = (u) => {
        if (!user || (u.username != user.username && u.access_token != user.access_token && u.refresh_token != user.refresh_token)) {
            setUser({
                username: u.username,
                access_token: u.access_token,
                refresh_token: u.refresh_token
            });
        }
        props.storage.saveSetting('username', u.username);
        props.storage.saveSetting('access_token', u.access_token);
        props.storage.saveSetting('refresh_token', u.refresh_token);
    }

    // server config
    const [user, setUser] = React.useState(loadUser())
    const [config, setConfig] = React.useState({});
    const [geoIP, setGeoIP] = React.useState(null);
    // const [noticeCount, setNoticeCount] = React.useState(0);
    // const [noticesOpen, setNoticesOpen] = React.useState(false);
    const [patrons, setPatrons] = React.useState([])
    useEffect(() => {
        props.api.getConfig(c => {
            setConfig(c);
            // geoIP look up data for this connection
            // props.api.getGeoIP(d => {
            //     setGeoIP(d);
            // })
            // check if we have credentials
            let username = props.storage.loadSetting("username");
            let access_token = props.storage.loadSetting("access_token");
            let refresh_token = props.storage.loadSetting('refresh_token');

            if (username && access_token) {
                props.api.userAuthenticate(username, access_token, refresh_token, (result) => {
                    switch (result.type) {
                        case "success":
                            updateUserCredentials(result);
                            break;

                        case "error":
                        default:
                            console.error(`Authentication failed\n${result.message}`);
                            setUser(null);
                            break;
                    }
                });
            }
        });
        props.api.getPatronList((results) => {
            setPatrons(results);
        })
    }, [])

    const searchProducts = (match) => {
        let search = searchText.trim();
        let filters = {
            region,
            price: {
                from: priceFrom,
                to: priceTo,
            },
        }
        props.api.searchProducts(search, geoIP ? geoIP.countryCode : null, resultsPerPage, currentResultsPage, filters, (results) => {
            if (results.error == null) {
                setSearchResults(results);
            } else {
                switch (results.error) {
                    case 429: // too many requests
                        // do nothing at the moment...
                        break;
                    default:
                        setSearchResults(results);
                        break;
                }
            }
        });
    }

    const searchReleases = (match) => {
        let filters = {
            region,
            price: {
                from: priceFrom,
                to: priceTo,
            },
        }

        props.api.searchProductsReleasedByDay(match.params.when.trim(), geoIP ? geoIP.countryCode : null, resultsPerPage, currentResultsPage, filters, (results) => {
            if (results.error == null) {
                setSearchResults(results);
            } else {
                switch (results.error) {
                    case 429: // too many requests
                        // do nothing at the moment...
                        break;
                    default:
                        setSearchResults(results);
                        break;
                }
            }
        })
    }

    const searchMostSearched = (match) => {
        props.api.searchProductsMostSearched(geoIP ? geoIP.countryCode : null, (results) => {
            results.maxResults = results.results.length;
            setSearchResults(results)
        })
    }

    const searchPopular = (match) => {
        let filters = {
            region,
            price: {
                from: priceFrom,
                to: priceTo,
            },
        }

        props.api.searchProductsMostSearched(geoIP ? geoIP.countryCode : null, filters, (results) => {
            results.maxResults = results.results.length;
            setSearchResults(results)
        })
    }

    const routes = [
        { route: "/search/:searchText", resultType: 1, func: searchProducts },
        { route: "/releases/:when", resultType: 2, func: searchReleases },
        { route: "/mostsearched", resultType: 3, func: searchMostSearched },
        { route: "/popular", resultType: 4, func: searchPopular },
        { route: "/signup", resultType: 5 },
        { route: "/login", resultType: 6 },
        { route: "/alerts", resultType: 7 },
        { route: "/supportus", resultType: 8 },
        { route: "/patrons", resultType: 9 },
        { route: "/forgotpassword", resultType: 10 },
        { route: "/supportedsites", resultType: 11 },
    ];
    const matchRoute = () => {
        let result;
        for (let route of routes) {
            let match = useRouteMatch(route.route);
            if (match) {
                result = {
                    route: route.route,
                    type: route.resultType,
                    params: match.params,
                    func: route.func,
                }
            }
        }
        return result;
    }
    let match = matchRoute();

    const [searchText, setSearchText] = React.useState(match && match.type == 1 ? match.params.searchText : "");
    const [searchResults, setSearchResults] = React.useState(null);
    const [resultsPerPage, setResultsPerPage] = React.useState(50);
    const [currentResultsPage, setCurrentResultsPage] = React.useState(0);
    const [region, setRegion] = React.useState(props.storage.loadSetting('region', 'All'));
    const [priceFrom, setPriceFrom] = React.useState(null);
    const [priceTo, setPriceTo] = React.useState(null);

    // This is the magic routing logic
    useEffect(() => {
        if (match /*&& geoIP*/ && match.func) {
            match.func(match);
        }
    }, [region, geoIP, currentResultsPage, searchText, priceFrom, priceTo]);

    const onDarkModeSwitch = () => {
        props.toggleDarkMode(!props.darkMode);
    }
    const onOptionsClick = (e) => {
        setMenuAnchorElement(e.target);
    }
    const handleMenuClose = (e) => {
        if (typeof e == 'string') {
            switch (e) {
                case MENU_OPTIONS.alerts:
                    if (user) {
                        window.location = '/alerts';
                    } else {
                        window.location = '/login';
                    }
                    break;

                case MENU_OPTIONS.login:
                    window.location = '/login';
                    break;

                case MENU_OPTIONS.signup:
                    window.location = '/signup';
                    break;

                case MENU_OPTIONS.today:
                    window.location = `/releases/today`;
                    break;

                case MENU_OPTIONS.yesterday:
                    window.location = '/releases/yesterday';
                    break;

                case MENU_OPTIONS.popular:
                    window.location = '/popular';
                    break;

                case MENU_OPTIONS.mostSearched:
                    window.location = '/mostsearched';
                    break;

                case MENU_OPTIONS.kickstarter:
                    window.location = '/search/kickstarter';
                    break;

                case MENU_OPTIONS.lightdarkmode:
                    onDarkModeSwitch();
                    setMenuAnchorElement(null);
                    break;

                case MENU_OPTIONS.support:
                    window.location = '/supportus';
                    break;

                case MENU_OPTIONS.logout:
                    logoutUser();
                    window.location.reload();
                    break;

                default:
                    console.error(`Menu option not implemented: ${e}`);
                    setMenuAnchorElement(null);
                    break;
            }
        } else {
            setMenuAnchorElement(null);
        }
    }
    const onSearchSubmit = (newSearchText) => {
        newSearchText = newSearchText.trim();
        if (newSearchText.length > 0 && newSearchText != searchText.trim()) {
            window.location = `/search/${encodeURIComponent(newSearchText)}`;
        }
    }

    const onPageChange = (newPage) => {
        setCurrentResultsPage(newPage);
    }

    const onRegionChange = (newRegion) => {
        setRegion(newRegion);
        setCurrentResultsPage(0);
        props.storage.saveSetting('region', newRegion);
    } 

    const setPriceRange = (from, to) => {
        setPriceFrom(from);
        setPriceTo(to);
    }
    const onPriceRangeChange = (from, to) => {
        setPriceRange(from, to);
        setCurrentResultsPage(0);
    }

    const onLinkClick = (searchText, item) => {
        let event = {
            event_type: 'product_click',
            data: {
              item: item,
              search_text: searchText,
            }
          };
      
          // make sure that the product item has an id
          event.data.item.id = 0;

          fetch(props.api.endpoints.API_EVENT_POST, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(event)
          }, (e) => {
            // console.log(e);
            // console.log('click submitted');
          })
      
    }


    // const onNoticesToggle = (e) => {
    //     setNoticesOpen(!noticesOpen);
    // }

    const onSubmitLogin = (email, password) => {
        props.api.userLogin(email, password, (result) => {
            console.log(result.type);
            switch (result.type) {
                case "success": {
                    updateUserCredentials(result);
                    window.location = '/';
                }
                    break;

                case "error":
                    alert(result.message)
                    break;
            }
        })
    }

    const onSubmitForgotPassword = (email) => {
        props.api.userForgotPassword(email, (result) => {
            switch (result.type) {
                case "success":
                    break;

                case "error":
                    alert(result.message);
                    break;
            }
        })
    }

    const onSubmitChangePassword = (email, newPassword, token) => {
        props.api.userChangePassword(email, newPassword, token, (result) => {
            switch (result.type) {
                case "success":
                    window.location = "/login";
                    break;

                case "error":
                    alert(result.message);
                    break;
            }
        })
    }

    const onSubmitSignup = (email, password, commsConsent) => {
        props.api.userSignup(email, password, commsConsent, (result) => {
            switch (result.type) {
                case "success":
                    updateUserCredentials(result);
                    window.location = '/';
                    break;

                case "error":
                    alert(result.message);
                    break;
            }
        });
    }

    const [backToTopVisible, setBackToTopVisible] = React.useState(false);
    const onScroll = (e) => {
        setBackToTopVisible(e.target.scrollTop > 100)
    }
    const onBackToTopClicked = (e) => {
        let anchor = document.getElementById("back-to-top-anchor");
        anchor.scrollIntoView({behavior: 'smooth', block: 'center'})
    }

    const renderSearchResultsArea = () => {
        return (
            <React.Fragment>
                <NoticeArea
                    notices={config.notices}
                />

                <AdvertArea
                    ads={config.ads}
                    api={props.api}
                />
                <ResultsArea
                    geoIP={geoIP}
                    currencies={CURRENCIES}
                    sites={config.sites}
                    regions={config.regions}
                    region={region}
                    exchangeRates={config.exchangeRates}
                    searchResults={searchResults}
                    resultsPerPage={resultsPerPage}
                    storage={props.storage}
                    onLinkClick={onLinkClick}
                    onPageChange={onPageChange}
                    onRegionChange={onRegionChange}
                    onPriceRangeChange={onPriceRangeChange}
                />
                {PATREON_ID ?
                <ShopLogoArea
                    patrons={patrons}
                />
                : null
                }

            </React.Fragment>
        )
    }

    const renderContentArea = () => {
        if (match) {
            switch (match.type) {
                case 1:
                case 2:
                case 3:
                case 4:
                    return renderSearchResultsArea()

                case 5:
                    return <SignupArea
                        onSubmitSignup={onSubmitSignup}
                    />

                case 6:
                    return <LoginArea
                        onSubmitLogin={onSubmitLogin}
                    />;

                case 7:
                    if (user) {
                        return <AlertsArea
                            api={props.api}
                            user={user}
                        />
                    } else {
                        window.location = '/login';
                    }

                case 8: // support us
                    return <SupportUs />

                case 9: // patrons
                    return <PatronsList
                        patrons={patrons}
                    />

                case 10: // forgot password
                    return <ForgotPasswordForm
                        onSubmitForgotPassword={onSubmitForgotPassword}
                        onSubmitChangePassword={onSubmitChangePassword}
                    />

                case 11: // supported sites
                    if (config && config.sites) {
                        return <SupportedSites
                            sites={config.sites}
                            email={INFO_EMAIL}
                        />
                    }
            }
        } else {
            return <LandingPage 
                API={props.api}
                patrons={patrons}
                carouselContent={config.carouselContent} />
        }
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <div className={classes.titleAndSearch}>
                        <Title />
                        <SearchBar
                            searchText={searchText}
                            onOptionsClick={onOptionsClick}
                            onSearchSubmit={(searchText) => onSearchSubmit(searchText)}
                        />
                    </div>
                    <Menu
                        id="simple-menu"
                        anchorEl={menuAnchorEl}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        keepMounted
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                    >

                        {
                            user ?
                                <MenuItem key="username" disabled={true}>
                                    {user.username}
                                </MenuItem>
                                : null
                        }
                        <MenuItem onClick={(e) => handleMenuClose(MENU_OPTIONS.alerts)}>
                            <NotificationImportantIcon className={classes.menuIcon} />
                            Product Alerts
                        </MenuItem>
                        {
                            user == null ?
                                [
                                    <MenuItem key="login" onClick={(e) => handleMenuClose(MENU_OPTIONS.login)}>
                                        <AccountCircleIcon className={classes.menuIcon} />
                                        Login
                                    </MenuItem>,
                                    <MenuItem key="signup" onClick={(e) => handleMenuClose(MENU_OPTIONS.signup)}>
                                        <LockOpenIcon className={classes.menuIcon} />
                                        Signup
                                    </MenuItem>
                                ]
                                : <MenuItem key="logout" onClick={(e) => handleMenuClose(MENU_OPTIONS.logout)}>
                                    <LogoutIcon className={classes.menuIcon} />
                                    Logout
                                </MenuItem>

                        }

                        <Divider className="divider" />
                        <MenuItem onClick={(e) => handleMenuClose(MENU_OPTIONS.today)}>
                            <TodayIcon className={classes.menuIcon} />
                            Today's Releases
                        </MenuItem>
                        <MenuItem onClick={(e) => handleMenuClose(MENU_OPTIONS.yesterday)}>
                            <EventIcon className={classes.menuIcon} />
                            Yesterday's Releases
                        </MenuItem>
                        <MenuItem onClick={(e) => handleMenuClose(MENU_OPTIONS.popular)}>
                            <WhatsHotIcon className={classes.menuIcon} />
                            Top 50 Popular Products
                        </MenuItem>
                        {/* <MenuItem onClick={(e) => handleMenuClose(MENU_OPTIONS.mostSearched)}>
                            <YoutubeSearchedForIcon className={classes.menuIcon} />
                            Most Searched for Products
                        </MenuItem> */}
                        {/* <Divider className="divider" /> */}
                        {/* <MenuItem onClick={(e) => handleMenuClose(MENU_OPTIONS.kickstarter)}>
                            <Typography className={classes.menuIcon} style={{ paddingLeft: 5, paddingRight: 5, fontSize: 20, fontWeight: "bold" }}>
                                K
                            </Typography>
                            Current Kickstarter Campaigns
                        </MenuItem> */}
                        <Divider className="divider" />
                        <MenuItem onClick={(e) => handleMenuClose(MENU_OPTIONS.lightdarkmode)}>
                            {props.darkMode
                                ? <React.Fragment>
                                    <LightModeIcon className={classes.menuIcon} /> Light Mode
                                </React.Fragment>
                                : <React.Fragment>
                                    <DarkModeIcon className={classes.menuIcon} /> Dark Mode
                                </React.Fragment>
                            }
                        </MenuItem>
                        {PATREON_ID 
                        ? <MenuItem>
                            <a className={classes.menulink} href={`https://www.patreon.com/bePatron?u=${PATREON_ID}`} target="_blank">
                                <MonetizationOnIcon className={classes.menuIcon} />
                                <span>Support us!</span>
                            </a>
                        </MenuItem>
                        : null
                        }
                        <Divider className="divider" />
                        {config ?
                            <MenuItem disabled>
                                {/* <InfoIcon className={classes.menuIcon} /> */}
                                {`${formatNumber(config.siteCount)} sites | ${formatNumber(config.deckCount)} decks | ${config.counters ? formatNumber(config.counters.search_count) : 0} searches`}
                            </MenuItem>
                            : null
                        }

                    </Menu>

                    {/* <IconButton
                        color="inherit"
                        className={classes.toolbarButton}
                        onClick={onNoticesToggle}
                    >
                        <Badge badgeContent={noticeCount} color="secondary">
                            <NotificationsIcon className={classes.toolbarIcon} />
                        </Badge>
                    </IconButton> */}
                </Toolbar>
            </AppBar>

            <main className={classes.content} onScroll={onScroll}>
                <div id="back-to-top-anchor" className={classes.appBarSpacer} />
                <Container maxWidth="xl" className={classes.container}>
                    {renderContentArea()}
                </Container>
                {backToTopVisible
                ? <Fab className={classes.backToTopButton} size="small" onClick={onBackToTopClicked} aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
                : null}       
            </main>
            <StickyFooter
                serverVersion={config.serverVersion}
            />
        </div>
    )
}

