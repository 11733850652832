import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    select: {
        marginLeft: 10,
        fontSize: 14,
        fontWeight: 'bold',
    },
    selectIcon: {
        fontSize: 22,
    }
}));

export default function CurrencySelector(props) {
    let classes = useStyles();

    return (
        <Select
            labelId="currency-select-label"
            id="currency-select"
            value={props.targetCurrency}
            onChange={props.onCurrencyChange}
            classes={{
                root: classes.select,
                select: classes.select,
                icon: classes.selectIcon,
            }}
        >
            {props.currencies.map(c => <MenuItem key={c} value={c}>{c}</MenuItem>)}
        </Select>

    )
}