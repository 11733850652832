import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardActions, CardContent, Grid, Link, Typography } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import WhatsHotIcon from '@material-ui/icons/Whatshot';

import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import ShopLogoArea from './ShopLogoArea';
import ResultsArea from './ResultsArea';

const PATREON_ID = process.env.REACT_APP_PATREON_ID
const HERO_AREA_HEIGHT = 300;

const useStyles = makeStyles((theme) => ({
    root: {
    },
    hero: {
        position: 'relative',
        height: HERO_AREA_HEIGHT,
        marginTop: theme.spacing(3),
    },
    carousel: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: HERO_AREA_HEIGHT,
        width: '100%',
        textAlign: 'center',
    },
    heroImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: HERO_AREA_HEIGHT,
        width: '100%',
        objectFit: 'cover',
        overflowX: 'hidden',
    },
    heroOverlay: {
        position: 'absolute',
        // top: 200,
        bottom: 30,
        left: 0,
        // height: 200,
        width: 500,
        fontSize: 24,
        fontWeight: 800,
        textAlign: 'left',
        padding: 30,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            width: 225,
        }
    },
    exploreHeader: {
        justifyContent: 'center',
        marginTop: 20,
    },
    explore: {
        flexDirection: 'row',
        justifyContent: "center",
        flexWrap: 'wrap',
        marginTop: 20,
    },
    exploreItem: {
        display: 'flex',
        flexDirection: 'column',
        width: 250,
        margin: 10,
    },
    patreon: {
        width: 230,
        marginTop: theme.spacing(-1.5),
    }
}));

const INFO_SITE_NAME = process.env.REACT_APP_SITE_NAME;
const INFO_CAROUSEL_BLURB = process.env.REACT_APP_CAROUSEL_MESSAGE || "Find what you want at the best prices";
const INFO_PATREON_ID = process.env.REACT_APP_PATREON_ID;

export default function LandingPage(props) {
    let classes = useStyles();
    
    return (
        <Grid container justifyContent="center" direction="column">
            <Grid item>
                <div className={classes.hero}>
                    <Carousel className={classes.carousel} content={props.carouselContent}/>
                    <Typography className={classes.heroOverlay}>
                        {INFO_CAROUSEL_BLURB}
                    </Typography>
                </div>
            </Grid>
            <Grid item className={classes.exploreHeader}>
                <Typography align="center" variant="h4">
                    Explore {INFO_SITE_NAME}
                </Typography>
                <Typography align="center" variant="body1">
                    <br/>Simply enter the name of a product, designer or any other search criteria into the search bar at the top and see what we can find.<br/>
                </Typography>

                {/* {searchResults 
                ? <ResultsArea 
                    geoIP={geoIP}
                    currencies={CURRENCIES}
                    sites={config.sites}
                    regions={config.regions}
                    region={region}
                    exchangeRates={config.exchangeRates}
                    searchResults={searchResults}
                    resultsPerPage={resultsPerPage}
                    storage={props.storage}
                    onPageChange={onPageChange}
                    onRegionChange={onRegionChange}
                    onPriceRangeChange={onPriceRangeChange} 
                />
                : null               
                } */}
                <Typography align="center" variant="body1">
                    You can also explore some of the cooler features of the site below that are also found in the menu options on the left of the search bar
                </Typography>
            </Grid>
            <Grid container className={classes.explore}>
                <Card variant="outlined" className={classes.exploreItem}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            <TodayIcon />&nbsp;Today's Releases
                        </Typography>
                        <Typography>
                            See all the products that have been released by retailers today
                        </Typography>
                    </CardContent>
                    <div style={{flexGrow: 1}} />
                    <CardActions>
                        <Button 
                            size="small" 
                            color="secondary"
                            onClick={(e) => window.location = '/releases/today'}
                        >
                            Take me there
                        </Button>
                    </CardActions>
                </Card>
                <Card variant="outlined" className={classes.exploreItem}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            <WhatsHotIcon/>&nbsp;Popular Products
                        </Typography>
                        <Typography gutterBottom>
                            See the products that other users have been interested in the most
                        </Typography>
                    </CardContent>
                    <div style={{flexGrow: 1}} />
                    <CardActions>
                        <Button 
                            size="small" 
                            color="secondary"
                            onClick={(e) => window.location = '/popular'}
                        >
                            Take me there
                        </Button>
                    </CardActions>
                </Card>
                <Card variant="outlined" className={classes.exploreItem}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            <NotificationImportantIcon/>&nbsp;Product Alerts
                        </Typography>
                        <Typography gutterBottom>
                            Set alerts for the products you want.
                            When we find something that matches your alert, we will let you know by email.
                        </Typography>
                        <Typography variant="caption">
                            (Requires an account to be created)
                        </Typography>
                    </CardContent>
                    <div style={{flexGrow: 1}} />
                    <CardActions>
                        <Button 
                            size="small" 
                            color="secondary"
                            onClick={(e) => window.location = '/alerts'}
                        >
                            Take me there
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            {INFO_PATREON_ID ?
            <Grid container className={classes.explore}>
                <a href={`https://www.patreon.com/bePatron?u=${PATREON_ID}`} target="_blank">
                    <img src="/PatreonWebsiteBadge.jpg" className={classes.patreon}/>
                </a>
                <ShopLogoArea
                    patrons={props.patrons}
                />                
                <a href={`https://www.patreon.com/bePatron?u=${PATREON_ID}`} target="_blank">
                    <img src="/PatreonWebsiteBadge.jpg" className={classes.patreon}/>
                </a>
            </Grid>
            : null
            }
        </Grid>
    )
}

function Carousel(props) {
    const classes = useStyles();
    let images = props.content;
    const [imageIndex, setImageIndex] = React.useState(0);
    
    const getImgSrc = () => {
        return images[imageIndex % images.length].image_url;
    }
    const getImgSearchText = () => {
        return images[imageIndex % images.length].search_term;
    }

    const getTargetLink = () => {
        let targetLink = images[imageIndex % images.length].target_url;
        return targetLink ? targetLink : `/search/${getImgSearchText()}`;
    }

    const getTargetLinkTarget = () => {
        let targetLink = images[imageIndex % images.length].target_url;
        return targetLink ? "_blank" : `_self`;
    }

    if (images && images.length) {
        setTimeout(() => {
            setImageIndex(imageIndex+1);
        }, images[imageIndex % images.length].delay_ms)
    }

    return (
        <div className={props.className}>
            {images && images.length
            ? <Link href={getTargetLink()} target={getTargetLinkTarget()}>
                <img src={getImgSrc()} className={classes.heroImage} />
            </Link>
            : null
            }
        </div>
    )    
    
}