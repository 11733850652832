import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        }
    },
    listItem: {
        display: 'inline-block',
    },
    image: {
        marginRight: theme.spacing(1),
        width: 'auto',
        height: 100,
        borderRadius: 10,
        [theme.breakpoints.down('sm')]: {
            height: 60,
            width: 'auto',
        },
    },
}));

export default function AdvertArea(props) {
    let classes = useStyles();

    const renderAdSpot = (spotNumber) => {
        let href = props.adEmail;
        let adSrc = `/adspot${spotNumber}.png`;
        let ad;
    
        if (props.ads) {
          ad = props.ads.find(ad => ad.ad_spot == spotNumber);
          if (ad) {
            href = `${props.api.endpoints.API_GET_AD_REDIRECT}/${ad.id}/${ad.impression_id}`;
            adSrc = ad.image_url;
          }
        }
    
        return (
            <Grid item key={`adspot${spotNumber}`} className={classes.listItem}>
                <a href={href} target="_blank">
                <img className={classes.image} src={adSrc}></img>
                </a>
            </Grid>
        )    
    }

    return (
        <Grid container justifyContent="center" className={classes.root}>
            {props.ads ? props.ads.map((ad) => renderAdSpot(ad.ad_spot)) : null}
         </Grid>
    )
}