import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Link, Typography } from '@material-ui/core';

const INFO_EMAIL = process.env.REACT_APP_EMAIL_INFO;
const INFO_SITENAME = process.env.REACT_APP_SITE_NAME;

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: "column",
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
    },
    header: {
        textAlign: "center",
    },
    link: {
        textDecoration: 'underline',
    },
    regionHeader: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    shopName: {
        fontSize: 16,
    }
}));

export default function SupportUs(props) {
    let classes = useStyles();

    return (
        <Container>
            <Typography variant="h5" className={classes.header}>
                How to support the site
            </Typography>
            <Typography variant="body">
                {INFO_SITENAME} is a passion project and is developed by Peter Mareš along with the help of various members of the 
                playing cards community. 
                We rely on the generosity of your users and retails to keep the site up and running and help us to continue developing
                and evolving the site.
            </Typography>
            <Grid container className={classes.explore}>
                <a href={`https://www.patreon.com/bePatron?u=${PATREON_ID}`} target="_blank">
                    <img src="/PatreonWebsiteBadge.jpg" className={classes.patreon}/>
                </a>
            </Grid>

        </Container>
    )
}