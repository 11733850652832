import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { lightTheme, darkTheme } from './theme';
import {apiInit, AppFrame, Utils} from "./fs-web-common-v2";
import {BrowserRouter as Router} from "react-router-dom"

export default function App(props) {
  const [darkMode, setDarkMode] = React.useState(Utils.loadSetting('darkMode', false));
  const toggleDarkMode = () => {
    Utils.saveSetting('darkMode', !darkMode);
    setDarkMode(!darkMode);
  }
  const api = apiInit(props.protocol, props.host, props.productType);

  return (
    <Router>
      <ThemeProvider theme={darkMode ? darkTheme() : lightTheme()}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <AppFrame
          darkMode={darkMode}
          toggleDarkMode={toggleDarkMode}
          storage={{
            saveSetting: Utils.saveSetting, 
            loadSetting: Utils.loadSetting, 
            deleteSetting: Utils.deleteSetting
          }}
          api={api}
        />
      </ThemeProvider>
    </Router>
  );
}