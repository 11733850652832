import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const PATREON_ID = process.env.REACT_APP_PATREON_ID;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 0,
        paddingTop: 0,
        paddingBottom: theme.spacing(1),
    },
    label: {
        fontSize: 16,
        fontWeight: 800,
        marginBottom: 10,
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        width: '100%',
    },
    logo: {
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
        height: 60,
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            height: 50,
        }
    },
    link: {
        color: theme.palette.text.primary,
        '&:visited': {
            color: theme.palette.text.primary,
        }
    }
}));

export default function ShopLogoArea(props) {
    let classes = useStyles();
    let patrons = props.patrons.filter(patron => patron.patron_type != "HistoricPatron" && patron.logo_asset);

    const label = patrons && patrons.length 
        ? <div className={classes.label}>
            Premium Retail Patrons:
          </div>
        : null;

    const renderPatron = p => {
        return (
            <a key={p.name} href={p.url} target="_blank">
                <img className={classes.logo} src={p.logo_asset} title={p.name} alt={p.name} />
            </a>
        )
    }

    return (
        <div className={classes.root} >
            {label}
            <div className={classes.container}>
                {patrons.map(p => renderPatron(p))}
            </div>
            <Typography variant="subtitle2">
                {'A big thank you goes to all our '}
                <a className={classes.link} href="/patrons">Patreon Supporters</a>
            </Typography>
            <Typography variant="subtitle2" style={{marginBottom: 10}}>
                <a className={classes.link} target="_blank" href={`https://www.patreon.com/bePatron?u=${PATREON_ID}`}>Help the development and maintenance</a>
            </Typography>
        </div>
    )
}