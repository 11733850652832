import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Link, Typography } from '@material-ui/core';

const PATREON_ID = process.env.REACT_APP_PATREON_ID;

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: "column",
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
    },
    header: {
        textAlign: "center",
    },
    link: {
        textDecoration: 'underline',
        color: theme.palette.text.primary,
    },
    patronHeader: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    patronName: {
        fontSize: 16,
    }
}));

export default function PatronsList(props) {
    let classes = useStyles();

    const renderData = () => {
        let currentPatronType = 0;
        return props.patrons.map((patron) => {
            let result = [];
            if (currentPatronType != patron.patron_type) {
                currentPatronType = patron.patron_type;
                let header = <div key={patron.patron_type} className={classes.patronHeader}><br /><br /><strong>{patron.patron_type_label}s</strong><br /><br /></div>
                result.push(header);
            }

            let entry = null;
            if (patron.url && patron.url.length > 0) {
                entry = (<a key={patron.name} href={patron.url} className={classes.link} target="_blank">
                    <div key={patron.name} className={classes.patronName}>{patron.name}</div>
                </a>)
            } else {
                entry = <div key={patron.name} className={classes.patronName}>{patron.name}</div>

            }

            result.push(entry);

            return result;
        })

    }

    return (
        <Grid container className={classes.root} alignItems="center">
            <Typography variant="h5" className={classes.header}>
                A huge thanks to our patrons who support the ongoing development of the site
            </Typography>
            <Typography variant="h6" className={classes.header}>
                If you would like to support the site, <Link className={classes.link} href={`https://www.patreon.com/bePatron?u=${PATREON_ID}`} target="_blank">please click this link</Link>
            </Typography>
            {renderData()}
        </Grid>
    )
}