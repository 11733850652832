import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    select: {
        marginLeft: 10,
        fontSize: 14,
        fontWeight: 'bold',
    },
    selectIcon: {
        fontSize: 22,
    }
}));

export default function RegionSelector(props) {
    let classes = useStyles();
    let selectedRegion = props.currentRegion ? props.currentRegion : 'All';

    return (
        <Select
            labelId="region-select-label"
            id="region-select"
            value={selectedRegion}
            onChange={props.onRegionChange}
            classes={{
                root: classes.select,
                select: classes.select,
                icon: classes.selectIcon,
            }}
        >
            <MenuItem key={"all"} value="All">All Regions</MenuItem>
            {props.regions ? props.regions.map(c => <MenuItem key={c} value={c}>{c}</MenuItem>) : null}
        </Select>

    )
}