import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, IconButton, Paper, Typography, TextField, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const MAX_ALERTS_PER_USER = 10;
const MIN_ALERT_LENGTH = 3;

const useStyles = makeStyles((theme) => ({
    root: {
        alignSelf: 'center',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        marginLeft: 0,
        marginRight: 0,
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        }
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    button: {
        padding: theme.spacing(2),
        marginLeft: theme.spacing(1)
    },
    header: {
        fontSize: 16,
        fontWeight: 800,
    },
    row: {
        padding: 0,
    },
    actionButton: {
        marginRight: theme.spacing(2),
    },
    red: {
        color: 'red',
    }
}));

export default function AlertsArea(props) {
    let classes = useStyles();

    const [newAlertText, setNewAlertText] = React.useState("");
    const [alerts, setAlerts] = React.useState([]);
    useEffect(() => {
        if (props.user) {
            props.api.getAlertList(props.user.access_token, (results) => {
                setAlerts(results.items)
            })

        }
    }, [props.user])

    const onNewAlertTextChange = (e) => {
        setNewAlertText(e.target.value);
    }

    const onAddAlertClicked = (e) => {
        e.preventDefault();
        if (newAlertText.length > MIN_ALERT_LENGTH) {
            props.api.addAlert(newAlertText, props.user.access_token, (result) => {
                setAlerts(result);
                setNewAlertText("");
            })
        } else {
            alert("To keep the alerts sytem from driving you mad with lots of alerts, please enter an alert of 4 or more characters");
        }
    }

    const onDeleteAlertClicked = (alert) => {
        props.api.deleteAlert(alert.id, props.user.access_token, (result) => {
            setAlerts(result)
        })
    }

    const renderAlerts = () => {
        let rows = alerts.map((a) => {
            return (
                <TableRow key={a.id}>
                    <TableCell style={{ padding: 0 }}>
                        <IconButton 
                            className={clsx(classes.actionButton, classes.red)}
                            onClick={(e) => onDeleteAlertClicked(a)}
                        >
                            <DeleteForeverIcon />
                        </IconButton>
                        {a.alert_text}
                    </TableCell>
                </TableRow>
            )
        })

        return rows;
    }

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.root}>
            <Typography variant="h3">
                Product Alerts
            </Typography>
            <Typography variant="body1" align="center">
                <br />
                Create alerts for products that you are interested in being notified about when they become available.<br/>
                When a product matches your alert, you will receive an email informing you of its availability, price and shop.
                <br />
            </Typography>
            <Typography variant="h6">
                <br />
                (Currently limited to 10 alerts per user)
                <br />
            </Typography>

            <form className={classes.form} noValidate onSubmit={onAddAlertClicked}>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    <TextField
                        style={{ width: '50%' }}
                        autoComplete="alert"
                        name="alert"
                        variant="outlined"
                        required
                        fullWidth
                        id="alert"
                        label="Create an alert for ..."
                        autoFocus
                        onChange={onNewAlertTextChange}
                        value={newAlertText}
                    />
                    <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        disabled={alerts.length >= MAX_ALERTS_PER_USER}
                        onClick={onAddAlertClicked}
                    >
                        Create Alert
                    </Button>
                </Grid>
            </form>

            <TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.header}>Alert</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderAlerts()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}