import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, IconButton, InputBase, Paper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import DeleteIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme) => ({
    root: {
        marginRight: theme.spacing(1),
        display: 'flex',
        width: '100%'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
        color: theme.palette.primary,
    },
    divider: {
        height: 40,
        margin: 4,
    },
}));

export default function SearchBar(props) {
    const classes = useStyles();

    const [searchText, setSearchText] = React.useState(props.searchText);

    const onSearchTextChange = (e) => {
        setSearchText(e.target.value);
        e.preventDefault();
    }

    const onSubmit = (e) => {
        e.preventDefault();
        props.onSearchSubmit(searchText);
    }

    const onClearSearch = (e) => {
        e.preventDefault();
        setSearchText("");
    }

    return (
        <Paper component="form" onSubmit={onSubmit} className={classes.root}>
            <IconButton 
                // color="primary"
                onClick={props.onOptionsClick}
            >
                <MenuIcon />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
            <InputBase
                className={classes.input}
                placeholder="Search"
                value={searchText}
                onChange={onSearchTextChange}
                autoFocus={true}
                name="search"
                autoComplete="search"
            />
            <IconButton
                onClick={onClearSearch}
            >
                <DeleteIcon />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
                aria-label="search"
                onClick={onSubmit}
            >
                <SearchIcon />
            </IconButton>

        </Paper>

    )
}