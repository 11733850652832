import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';

const INFO_LOGO_DESIGNER = process.env.LOGO_DESIGNER;
const INFO_LOGO_DESIGNER_URL = process.env.LOGO_DESIGNER_URL;

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 'auto',
        maxWidth: '100%',
        backgroundColor: theme.palette.footer.backgroundColor,
        color: theme.palette.footer.color,
        textAlign: 'center',
        borderTop: 'solid 1px grey',
    },
    link: {
        color: theme.palette.footer.color,
        "&:visited": {
            color: theme.palette.footer.color,
        }
    }
}));

export default function StickyFooter(props) {
    let classes = useStyles();

    return (
        <footer className={classes.root}>
            <Container maxWidth="xl">
                <Typography variant="caption">
                    {`v${props.serverVersion} | `}
                    {`Developed by `}
                    <a className={classes.link} href="mailto:peter@sci-ent.com">Peter Mareš</a>
                    {` | Copyright sci:ent, 2021-${new Date().getFullYear()} | 
                    Powered by FindStar Technology | `}
                    {INFO_LOGO_DESIGNER ? 
                    `Logo designed by ` +
                    <a className={classes.link} href={LOGO_DESIGNER_URL}>{LOGO_DESIGNER}</a> + ' | '
                    : null }
                    <a className={classes.link} href="/supportedsites">Supported Sites</a>

                </Typography>
            </Container>
        </footer>
    )
}