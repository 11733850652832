export function saveSetting(k, v) {
    localStorage.setItem(k, v);
}

export function loadSetting(k, defaultValue) {
    let v = localStorage.getItem(k);
    switch (v) {
        case "true": v = true; break;
        case "false": v = false; break;
    }
    return v != undefined ? v : defaultValue;
}

export function deleteSetting(k) {
    localStorage.removeItem(k);
}

export function formatNumber(num) {
    if (num !== undefined) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    return num;
}

export function currencySymbolToCode(symbol) {
    switch (symbol) {
        case "$": return 'USD';
        case "€": return 'EUR';
        case "£": return 'GBP';
        case "A$": return 'AUD';
        case "S$": return 'SGD';
        default: return symbol;
    }
}

export function currencyCodeToSymbol(code) {
    switch (code) {
        case "USD": return '$';
        case "EUR": return '€';
        case "GBP": return '£';
        case "AUD": return 'A$';
        default: return code;
    }
}